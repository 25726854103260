import React, { useContext, useState } from "react";  
import { AppContext } from "../App";
import './LoginScreen.css';
import { Button } from "bootstrap";
import { SendApiPost } from "../components/Api/ApiRequests";
import { parseToken } from "./AuthService";
import { useNavigate } from "react-router-dom";


const LoginScreen = () => {

    const [appData, setAppData] = useContext(AppContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorLogin, setErrorLogin] = useState(false);

    const navigate = useNavigate();

    const onLogin = () => {

        SendApiPost(`/api/Auth/login`, {username: username, password: password}, onSuccesLogin, onErroLogin)

    }

    const onSuccesLogin = (response) => 
    {
        localStorage.setItem("token", response.token);
        var tokenData = parseToken(response.token); 
    
        var data = {
          token: response.token,
          isAuthenticated: true,
          userName: tokenData.sub,
          roles: tokenData.roles,
          webParams: {}
        }

        console.log(data);
    
        setAppData(data);
        navigate('/');


    }

    const onErroLogin = (error) => {
        setErrorLogin(true);

    
    }

    return (<div className="loginScreen">
        <div className="loginWindow">
        <div className="loginForm">
                <h2 className="formTitle">LUCA MANAGEMENT APP</h2>

                <input type="text" className="formField" value={username} placeholder="Username" onChange={(event) => {setUsername(event.target.value); setErrorLogin(false);}}/>
                <input type="password" className="formField" value={password} placeholder="Password"  onChange={(event) => {setPassword(event.target.value); setErrorLogin(false);}}/>
                {errorLogin && <div className="errorLoginMessage">Username or pasword are incorrect</div>}
                <button disabled={errorLogin} onClick={onLogin}>Confirm</button>
            </div>
            
        </div>
        </div>)
}

export default LoginScreen;