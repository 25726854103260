import React, { useEffect, useState, useContext } from "react";
import { SendApiGet } from "../Api/ApiRequests";
import Paging from '../Paging/Paging';
import { Button, Col, Input, Row } from "reactstrap";

const Statistics = () => {
    const emptyRequest = {
        timeFrom: '',
        timeTo: '',
        page: 0,
        maxCount: 1000
    };

    const [statList, setStatList] = useState(null);
    const [request, setRequest] = useState(emptyRequest);
    

    useEffect(() => {
        setStartupTimes();
    }, []);

    const setStartupTimes = () => {
        let newData = { ...request };

        if (newData.timeFrom === '')
            newData.timeFrom = new Date(GetLocalDate() - 8 * 60 * 60 * 1000)
                .toISOString()
                .substring(0, 16);

        if (newData.timeTo === '')
            newData.timeTo = GetLocalDate().toISOString().substring(0, 16);

        console.log(newData);
        setRequest(newData);
    };

    const GetLocalDate = () => {
        let date = new Date().toString();
        return new Date(`${date.replace(/-/g, '/')} UTC`);
    }

    const loadStats = () => {
        SendApiGet(
            `/api/Statistics/${request.maxCount}/${request.page}/${request.timeFrom}/${request.timeTo}`,
            (response) => {
                setStatList(response);
                console.log(response);
            },
            (error) => {
            }
        );
    };

    const onValueChange = (event) => {
        let newData = { ...request };

        newData[event.target.name] = event.target.value;

        setRequest(newData);
    };

    const getHumanTime = (time) =>
        new Date(time).toLocaleDateString("pl-PL",
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            });


    return (
        <div>
            <Row>
                <Col className="col-12 rounded bg-seco2ndary text-center p-2">
                    <label>
                        Od: <input
                            type='datetime-local'
                            name='timeFrom'
                            className="me-2"
                            value={request.timeFrom}
                            onChange={(event) => onValueChange(event)}
                        />
                    </label>
                    <label>
                        Do: <input
                            type='datetime-local'
                            name='timeTo'
                            value={request.timeTo}
                            onChange={(event) => onValueChange(event)}
                        />
                    </label>
                    <Button className="btn btn-success ms-4 px-4" onClick={loadStats}>
                        Wyszukaj
                    </Button>
                </Col>

                <Col className="col-12 text-center text-white">
                    {statList ?
                        <Paging
                            header={
                                <Row className="m-1 p-2 rounded bg-primary">
                                    <Col>Czas</Col>
                                    <Col>Nazwa</Col>
                                    <Col>Klucz</Col>
                                    <Col>Wartość</Col>
                                </Row>
                            }
                            elements={statList.map((stat) => (
                                <Row key={stat.id} className="m-1 p-1 rounded bg-secondary text-break">
                                    <Col>{getHumanTime(stat.insertTime)}</Col>
                                    <Col>{stat.dataName}</Col>
                                    <Col>{stat.dataKey}</Col>
                                    <Col>{stat.value}</Col>
                                </Row>
                            ))}
                        /> :
                        <Row className="text-white text-center">
                            <Col>
                                <p className="rounded bg-secondary p-2">Wybierz zakres czasu.</p>
                            </Col>
                        </Row>
                    }
                </Col>
                
            </Row>
        </div>
    );

}

export default Statistics;