import axios from 'axios';
import { toast } from 'react-toastify';

const GlobalConfig = () => {

    return { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
};


export async function SendApiGetWithData(url, data, successAction = null, failAction = null, config = GlobalConfig()) {
    let response = axios.get(url + '?' + new URLSearchParams(data).toString(), config);

    response.then(response => {
        ProcessApiSuccess(response, successAction);
    }).catch(error => {
        ProcessApiError(error, failAction);
    });

    return await response;
}

export async function SendApiGet(url, successAction = null, failAction = null, config = GlobalConfig()) {
    let response = axios.get(url, config);

    response.then(response => {
        ProcessApiSuccess(response, successAction);
    }).catch(error => {
        ProcessApiError(error, failAction);
    });

    return await response;
}

export async function SendApiPost(url, data, successAction = null, failAction = null, config = GlobalConfig()) {
    let response = axios.post(url, data, config)

    response.then(response => {
        ProcessApiSuccess(response, successAction);
    }).catch(error => {
        ProcessApiError(error, failAction);
    });

    return await response;
}

export async function SendApiPut(url, data, successAction = null, failAction = null, config = GlobalConfig()) {
    let response = axios.put(url, data, config)

    response.then(response => {
        ProcessApiSuccess(response, successAction);
    }).catch(error => {
        ProcessApiError(error, failAction);
    });

    return await response;
}

export async function SendApiDelete(url, successAction = null, failAction = null, config = GlobalConfig()) {
    let response = axios.delete(url, config)

    response.then(response => {
        ProcessApiSuccess(response, successAction);
    }).catch(error => {
        ProcessApiError(error, failAction);
    });

    return await response;
}


function ProcessApiSuccess(response, successAction) {
    //console.log(response.data);

    if (successAction != null) {
        let text = successAction(response.data);

        if (text == "")
            return;

        if (text != null)
            toast.success(text);
    }
}

function ProcessApiError(error, failAction) {
    console.log(error);

    if (failAction != null) {
        let text = failAction(error.response);

        if (text == "")
            return;

        if (text != null)
            toast.error(text);
        else
            toast.error(error.message);
    }
    else
        toast.error(error.message);
}