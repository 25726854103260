import React, { useEffect, useState } from 'react'

const Paging = (props) => {
    const [numberOfRows, setNumberOfRows] = useState(15);
    const [page, setPage] = useState(1);

    let numberOfPages = 1;


    useEffect(() => {
        numberOfPages = Math.ceil(props.elements.length / numberOfRows).toFixed(0);
        setPage(1);
    }, [props.elements])

    const handlePageInput = (value) => {
        let val = Number.parseInt(value);

        if (val >= 1 && val <= numberOfPages)
            setPage(val);
    }

    const buildPaging = () => {
        if (props.elements == null || props.elements.length <= 0)
            return;

        numberOfPages = Math.ceil(props.elements.length / numberOfRows).toFixed(0);

        let pages = []
        pages.push(<button className={"btn btn-sm me-1 mb-2 btn-" + (page === 1 ? "secondary disabled" : "primary")} onClick={() => { if (page === 1) return; setPage(page - 1) }}>{"<<"}</button>);
        for (let i = 1; i <= numberOfPages; i++) {
            if ((page < 5 && i < 10) || (i < page + 5 && i > page - 5) || (page > numberOfPages - 5 && i > numberOfPages - 9))
                pages.push(<div className={"btn btn-sm mb-2 me-1 btn-primary" + (page === i ? " disabled" : "")} onClick={() => setPage(i)}>{i}</div>);
        }
        pages.push(<div className={" btn btn-sm mb-2 btn-" + (page >= numberOfPages ? "secondary disabled" : "primary")} onClick={() => { if (page >= numberOfPages) return; setPage(page + 1) }}>{">>"}</div>);

        return pages;
    }

    const buildGoTo = () => {
        if (props.elements == null || props.elements.length <= 0)
            return;

        return (
            <p className="mb-2">
                Idź do:
                <input className="mx-2" type="number" min="1" max={numberOfPages} defaultValue={page} onChange={(event) => handlePageInput(event.target.value)} />
                z {numberOfPages}
            </p>
        );
    }

    return (<>
        <div className="col-12 my-2 text-black text-left">
            Liczba wyników na stronę:&nbsp;
            <select className="customInput" style={{ maxWidth: "100px" }} value={numberOfRows} onChange={event => { setNumberOfRows(event.target.value); setPage(1) }}>
                <option>10</option>
                <option>15</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
                <option>300</option>
                <option>500</option>
                <option>1000</option>
                <option>2000</option>
            </select>
        </div>

        <div className="col-12 text-center">
            {buildPaging()}
            {buildGoTo()}
        </div>
        
        {props.header &&
            <>{props.header}</>
        }

        {props.elements == null || props.elements.length ?
            props.elements.slice((page - 1) * numberOfRows, page * numberOfRows).map(element =>
                <>{element}</>) :
            <>
                <div className="text-center bg-secondary rounded text-white py-2">Brak wyników.</div>
            </>
        }


        <div className="col-12 text-center">
            {buildPaging()}
            {buildGoTo()}
        </div>
    </>)

}

export default Paging;