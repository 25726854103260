import React from "react";
import TopMenu from "./components/TopMenu/TopMenu";
import { Container } from "reactstrap";
import Home from "./components/Home/Home";
import { Route, Routes } from "react-router-dom";
import DataSets from "./components/DataSets/DataSets";
import DataSetDetails from "./components/DataSets/DataSetDetails";

import Users from "./components/Users/Users";
import UserDetails from "./components/Users/UserDetails";

import Statistics from "./components/Statistics/Statistics";
import Status from "./components/Status/Status";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AuthenticatedApp = () => {
    return (
        <div>
            <TopMenu />
            <Container fluid="md" className="bg-light" style={{ minHeight: "100vh" }}>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                        <Route path="/DataSets/*">
                            <Route index element={<DataSets />} />
                            <Route path=":id" element={<DataSetDetails />} />
                        </Route>
                        <Route path="/Statistics/*" element={<Statistics />} />
                        <Route path="/Status/*" element={<Status />} />

                        <Route path="/Users/*">
                            <Route index element={<Users />} />
                            <Route path=":id" element={<UserDetails />} />
                        </Route>
                    </Route>
                </Routes>
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
};

export default AuthenticatedApp;
