import React, { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import './custom.css';
import AuthenticatedApp from './AuthenticatedApp';
import { SendApiGet } from './components/Api/ApiRequests';
import { parseToken } from './Authentication/AuthService';
import LoginScreen from './Authentication/LoginScreen';

export const AppContext = createContext(null);

const emptyAppData = {

  token: "",
  isAuthenticated: false,
  userName: "",
  roles: "",
  webParams: {}
}

const App = () =>  {

  const [appData, setAppData] = useState(emptyAppData);
  const navigate = useNavigate();

  useEffect(() => {

    validateUser();

  },[]);

  const validateUser = () => {
    SendApiGet('/api/Auth/Validate', userValid, userInValid);
  }

  const userValid = (response) => {

    var token = localStorage.getItem("token");
    var tokenData = parseToken(token); 

    var data =
    {
        token: token,
        isAuthenticated: true,
        userName: tokenData.sub,
        roles: tokenData.roles,
        webParams: {}
      }

      console.log(data);

    setAppData(data);
  }

  const userInValid = (response) => {
    setAppData(emptyAppData);
    navigate('/login');
  }

return (<>
  <AppContext.Provider value={[appData, setAppData]}>
    <Routes>
      <Route exact path='/login' element={<LoginScreen />} />
    </Routes>
    {appData.isAuthenticated &&
      <AuthenticatedApp />
      }
  </AppContext.Provider> 
</>);

}

export default App;