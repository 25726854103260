import React, { useEffect, useState, useContext } from "react";
import { SendApiGet } from "../Api/ApiRequests";
import { Button, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";

const DataSets = () => {
  const [dataSets, setDataSets] = useState([]);
    const [nameFilter, setNameFilter] = useState("");
    const [appData, setAppData] = useContext(AppContext);

  useEffect(() => {
      loadSets();
  }, []);

  const loadSets = () => {
    SendApiGet(
      "/api/Data/Sets",
      (response) => {
          setDataSets(response);
      },
      (error) => {}
    );
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className=" m-1 p-1 rounded bg-primary text-white align-items-center">
            <Col>Filtruj po nazwie zestawu danych</Col>
            <Col>
              <Input value={nameFilter} onChange={(event) => setNameFilter(event.target.value)} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className=" m-1 p-2 rounded bg-primary text-white align-items-center">
            <Col>
              <Link to="/DataSets/0">
                <Button className="btn-sm w-100 btn-success">Dodaj nowy zestaw danych</Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {dataSets &&
            dataSets
              .filter((x) => x.name.includes(nameFilter))
              .map((dataSet) => (
                <Row className="m-1 p-1 rounded bg-secondary text-white align-items-center">
                  <Col>{dataSet.name}</Col>
                  <Col className="col-2">
                    <Link to={`/DataSets/${dataSet.id}`}>
                      <Button className="btn-sm btn-warning w-100">Edytuj</Button>
                    </Link>
                  </Col>
                </Row>
              ))}
        </Col>
      </Row>
    </div>
  );
};

export default DataSets;
