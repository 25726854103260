import React, { useEffect, useState, useContext } from "react";
import { SendApiDelete, SendApiGet, SendApiPost, SendApiPut } from "../Api/ApiRequests";
import { Button, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const UserDetails = () => {
    const emptyUser = {
        id: 0,
        userName: "",
        pass: "",
        role: "Admin"
    };

    const params = useParams();
    let history = useNavigate();

    const [dbRoles, setDbRoles] = useState([]);
    const [editingUser, setEditingUser] = useState([]);

    useEffect(() => {
        loadRoles();
        loadUser();
    }, []);

    const loadRoles = () => {
        SendApiGet(
            `/api/Users/Roles`,
            (response) => {
                setDbRoles(response);
            },
            (error) => { }
        );
    };

    const loadUser = () => {
        if (params.id === "0") {
            setEditingUser(emptyUser);
            return;
        }

        SendApiGet(
            `/api/Users/${params.id}`,
            (response) => {
                setEditingUser(response);
                console.log(response);
            },
            (error) => { }
        );
    };

    const saveUser = () => {
        if (editingUser.id == 0)
            SendApiPost(
                `/api/Users/Add`,
                editingUser,
                (response) => {
                    setEditingUser(response);
                    history(`/Users/${response.id}`);
                    toast.success("Poprawnie dodano użytkownika");
                },
                (error) => {
                    if (error.data !== undefined)
                        return error.data.map(k => k.description).join('\r\n');
                }
            );
        else {
            SendApiPut(
                `/api/Users/Update`,
                editingUser,
                (response) => {
                    setEditingUser(response);
                    toast.success("Poprawnie zapisano zmiany");
                },
                (error) =>
                {
                    if (error.data !== undefined)
                        return error.data.map(k => k.description).join('\r\n');
                }
            );
        }
    };

    const onChangeData = (event) =>
    {
        let newData = { ...editingUser };
        newData[event.target.name] = event.target.value;

        console.log(newData);

        setEditingUser(newData);
    };


    return (
        <div>
            <Row>
                <Col>
                </Col>

                <Col className="text-center my-3">
                    <Link to="/Users">
                        <Button className="btn-md btn-primary w-50">Wróć</Button>
                    </Link>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Row className=" m-1 p-2 rounded bg-primary text-white align-items-center">
                        <Col>Nazwa użytkownika</Col>
                        <Col>Hasło</Col>
                        <Col className="col-2">Rola</Col>
                        <Col className="col-2"></Col>
                    </Row>

                    <Row className=" m-1 p-2 rounded bg-secondary text-white align-items-center">
                        <Col><Input name="userName" value={editingUser.userName} onChange={onChangeData} /></Col>
                        <Col><Input name="pass" value={editingUser.pass} type="password" onChange={onChangeData} /></Col>
                        <Col className="col-2">
                            <select name="role" value={editingUser.role} onChange={onChangeData}>
                                {dbRoles.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col className="col-2">
                            <Button className="btn-md btn-success p-1" onClick={() => saveUser()}>
                                {editingUser.id === 0 ? "Dodaj użytkownika" : "Zapisz zmiany"}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default UserDetails;