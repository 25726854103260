import React, { useEffect, useState } from "react";
import { SendApiDelete, SendApiGet, SendApiPost, SendApiPut } from "../Api/ApiRequests";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Input, Row } from "reactstrap";
import { toast } from "react-toastify";

const DataSetDetails = (props) => {
  const emptyDataSet = {
    id: 0,
    name: "",
    properties: [],
  };

  const params = useParams();
  let history = useNavigate();
  const [currentDataSet, setCurrentDataSet] = useState(emptyDataSet);
  const [dataProperties, setDataProperties] = useState(null);
  const [propertyLanguages, setPropertyLanguages] = useState([{ name: "Polski", languageKey: "pl" }]);

  const [newKey, setNewKey] = useState("");
  const [dataKeys, setDataKeys] = useState([]);

  useEffect(() => {
    loadDataSet();
  }, []);

  useEffect(() => {
    loadDataSetKeys();
  }, [currentDataSet]);

  const loadDataSet = () => {
    if (params.id === "0") {
      loadConfig(emptyDataSet);
      return;
    }
    SendApiGet(
      `/api/Data/Set/${params.id}`,
      (response) => {
        loadConfig(response);
      },
      (error) => {}
    );
  };

  const loadConfig = (dataSet) => {
    SendApiGet(
      `/api/Configs/DataProperties`,
      (response) => {
        setDataProperties(response);
        loadLanguages(response, dataSet);
      },
      (error) => {}
    );
  };

  const loadLanguages = (dataProperties, dataSet) => {
    SendApiGet(
      `/api/Configs/Languages`,
      (response) => {
        setPropertyLanguages(response);
        console.log(JSON.stringify(response));
        buildPropertiesList(dataProperties, dataSet, response);
      },
      (error) => {}
    );
  };

  const buildPropertiesList = (currentDataProperties, dataSet, languages) => {
    let newData = { ...dataSet };

    currentDataProperties
      .filter((x) => x.isLanguageDependent == false)
      .forEach((property) => {
        if (newData.properties.filter((x) => x.name.includes(property.name)).length <= 0) {
          newData.properties.push({
            id: 0,
            name: property.name,
            visibleName: property.visibleName,
            languageKey: "",
            value: "",
            propertyConfigId: property.id,
            propertyConfig: property,
          });
        }
      });

    languages.forEach((language) => {
      currentDataProperties
        .filter((x) => x.isLanguageDependent == true)
        .forEach((property) => {
          if (newData.properties.filter((x) => x.name.includes(property.name) && x.languageKey == language.languageKey).length <= 0)
            newData.properties.push({
              id: 0,
              name: property.name,
              visibleName: property.visibleName,
              languageKey: language.languageKey,
              value: "",
              propertyConfigId: property.id,
              propertyConfig: property,
            });
        });
    });

    console.log(newData);
    setCurrentDataSet(newData);
  };

  const getLanguageName = (languageKey) => {
    if (languageKey == "" || !languageKey) return "";

    var lang = propertyLanguages.find((x) => x.languageKey == languageKey);

    if (lang == undefined) return "";

    return lang.name;
  };

  const saveDataSet = () => {
    if (currentDataSet.id == 0)
      SendApiPost(
        `/api/Data/AddDataSet`,
        currentDataSet,
        (response) => {
          history(`/DataSets/${response.id}`);
          toast.success("Poprawnie dodano zestaw danych");
        },
        (error) => {}
      );
    else {
      SendApiPut(
        `/api/Data/UpdateDataSet`,
        currentDataSet,
        (response) => {
          setCurrentDataSet(response);
          toast.success("Poprawnie zapisano zmiany");
        },
        (error) => {}
      );
    }
  };

  const updateParam = (name, languageKey, value) => {
    let newData = { ...currentDataSet };
    let property = newData.properties.find((x) => x.name == name && x.languageKey == languageKey);
    if (property == undefined) return;
    property.value = value;

    setCurrentDataSet(newData);
  };

  const onChangeData = (event) => {
    let newData = { ...currentDataSet };

    newData[event.target.name] = event.target.value;

    setCurrentDataSet(newData);
  };

  const loadDataSetKeys = () => {
    if (currentDataSet.id == 0) return;

    SendApiGet(
      `/api/Data/DataKeys/${currentDataSet.id}`,
      (response) => {
        setDataKeys(response);
      },
      (error) => {}
    );
  };

  const addDataSetKey = () => {
    SendApiPut(
      `/api/Data/DataKey/`,
      { dataId: currentDataSet.id, dataKey: newKey, id: 0, data: null },
      (response) => {
        let newData = [...dataKeys];
        newData.push(response);
        setDataKeys(newData);
        toast.success("Poprawnie dodano identyfikator");
      },
      (error) => {}
    );
  };

  const deleteDataSetKey = (id) => {
    SendApiDelete(
      `/api/Data/DataKey/${id}`,
      (response) => {
        let newData = [...dataKeys];
        let index = newData.findIndex((x) => x.id === id);
        newData.splice(index, 1);
        setDataKeys(newData);
        toast.success("Poprawnie usunięto identyfikator");
      },
      (error) => {}
    );
  };

  const getOptions = (jsonString) => {
    console.log(jsonString);

    try {
      let a;
      a = JSON.parse(jsonString);
      console.log(a);
      return a;
    } catch (e) {
      return null;
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col>
              <Row className=" m-1 p-1 rounded bg-primary text-white align-items-center">
                <Col>Nazwa zestawu danych</Col>
                <Col>
                  <Input name="name" value={currentDataSet.name} onChange={onChangeData} />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className=" m-1 p-2 rounded bg-primary text-white align-items-center">
                <Button className="bg-success btn-sm" onClick={() => saveDataSet()}>
                  {currentDataSet.id === 0 ? "Dodaj zesatw" : "Zapisz zmiany"}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="m-1 mt-4 p-2 rounded bg-primary text-white align-items-center">
            <Col className="text-center">Identyfiaktory</Col>
          </Row>
          <Row>
            <Col>
              <Row className="m-1 p-2 rounded bg-success text-white align-items-center">
                <Col>
                  <Row className="text-white align-items-center text-center">
                    <Col>Nowy identyfiaktor</Col>
                    <Col>
                      <Input value={newKey} onChange={(event) => setNewKey(event.target.value)} />
                    </Col>
                    <Col>
                      <Button className="btn-sm btn-success border-white" onClick={addDataSetKey}>
                        Dodaj
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {dataKeys &&
                dataKeys.map((key) => (
                  <Row className="m-1 p-2 rounded bg-secondary text-white align-items-center text-center">
                    <Col>{key.dataKey}</Col>
                    <Col>
                      <Button onClick={() => deleteDataSetKey(key.id)} className="btn-sm btn-danger">
                        Usuń
                      </Button>
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>
          <Row className="m-1 mt-4 p-2 rounded bg-primary text-white align-items-center">
            <Col className="text-center">Parametry</Col>
          </Row>
          {currentDataSet &&
            currentDataSet.properties &&
            currentDataSet.properties.map((property, index) => (
              <Row className="m-1 p-1 bg-secondary text-white rounded">
                <Col
                  className="col-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>{index + 1}</span>
                </Col>
                <Col
                  className="col-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {getLanguageName(property.languageKey)}
                </Col>
                <Col
                  className="col-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {property.visibleName}
                </Col>
                {property.propertyConfig.propertyType == 0 && (
                  <Col className="col-6">
                    <Input value={property.value} onChange={(event) => updateParam(property.name, property.languageKey, event.target.value)} type="text" />
                  </Col>
                )}
                {property.propertyConfig.propertyType == 3 && (
                  <Col className="col-6">
                    <Input value={property.value} onChange={(event) => updateParam(property.name, property.languageKey, event.target.value)} type="textarea" />
                  </Col>
                )}
                {property.propertyConfig.propertyType == 4 && (
                  <Col className="col-6">
                    <Input value={property.value} onChange={(event) => updateParam(property.name, property.languageKey, event.target.value)} type="select">
                      <option value="">BRAK</option>
                      {getOptions(property.propertyConfig.options) &&
                        getOptions(property.propertyConfig.options).map((option) => <option value={option.value}>{option.name}</option>)}
                    </Input>
                  </Col>
                )}
              </Row>
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default DataSetDetails;
