import React, { useEffect, useState, useContext } from "react";
import { SendApiGet } from "../Api/ApiRequests";
import { Button, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";

const Users = () => {
    const [appData, setAppData] = useContext(AppContext);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        SendApiGet(
            "/api/Users",
            (response) => {
                setUserList(response);
            },
            (error) => {
            }
        );
    };

    return (
        <div>
            <Row>
                <Col>
                </Col>
                <Col className="text-center my-3">
                    <Link to="/Users/0">
                        <Button className="btn-md btn-success w-50">Dodaj użytkownika</Button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className=" m-1 p-2 rounded bg-primary text-white align-items-center">
                        <Col>Nazwa użytkownika</Col>
                        <Col>Rola</Col>
                        <Col className="col-2"></Col>
                    </Row>
                    {userList &&
                        userList
                            .map((user) =>
                            (
                                <Row className="m-1 p-1 rounded bg-secondary text-white align-items-center">
                                    <Col>{user.userName}</Col>
                                    <Col>{user.role}</Col>
                                    <Col className="col-2">
                                        <Link to={`/Users/${user.id}`}>
                                            <Button className="btn-sm btn-warning w-100">Edytuj</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            )
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default Users;