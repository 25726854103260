import React, { useEffect, useState, useContext } from "react";
import { SendApiGet } from "../Api/ApiRequests";
import { Button, Col, Input, Row } from "reactstrap";

const Status = () => {
    const [statList, setStatList] = useState(null);


    useEffect(() => {
        loadStats();
    }, []);

    const loadStats = () => {
        SendApiGet(
            "/api/Statistics/?name=Battery&name=Temperature",
            (response) => {
                let sList = [];

                response.map((stat) => {
                    let exists = sList.find(obj => obj.dataKey === stat.dataKey);

                    if (!exists)
                    {
                        sList = [...sList, stat];
                        exists = sList.find(obj => obj.dataKey === stat.dataKey);
                    }

                    exists.time = new Date(stat.insertTime).toLocaleDateString("pl-PL",
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                    });

                    if (stat.dataName == "Battery")
                    {
                        exists.battery = getValue(stat.value);
                        exists.batteryPerc = calculatePercentage(exists.battery);
                        exists.background = exists.batteryPerc > 25 ? "bg-secondary" : (exists.batteryPerc > 10 ? "bg-warning" : "bg-danger");
                    }
                    else if (stat.dataName == "Temperature")
                        exists.temp = getValue(stat.value);
                    
                });

                sList = [...sList].sort((a, b) => a.battery - b.battery);
                setStatList(sList);
            },
            (error) => {
            }
        );
    };

    const getValue = (val) =>
    {
        val = val.replace(',', '.');

        const isTemp = val.includes('.');
        const newVal = isTemp ? val : val.slice(0, 1) + '.' + val.slice(1);
        let asFloat = parseFloat(newVal);

        if (isNaN(asFloat))
            return 0;

        return asFloat.toFixed(2);// + (isTemp ? " °C" : " V (" + calculatePercentage(asFloat) + "%)");
    }

    const calculatePercentage = (input) => {
        const min = 2.5;
        const max = 3.0;

        const val = Math.min(Math.max(input, min), max);
        const perc = ((val - min) / (max - min)) * 100;

        return perc.toFixed(0);
    };

    const getBatteryString = (batt) => {
        if (batt == 0)
            return 0;

        return batt + "V (" + calculatePercentage(batt) + "%)";
    }

    return (
        <div>
            <Row>
                <Col>
                    <Row className=" m-1 p-2 rounded bg-primary text-white align-items-center text-center">
                        <Col>Ostatnie odświeżenie</Col>
                        <Col>Beacon</Col>
                        <Col>Temperatura</Col>
                        <Col>Bateria</Col>
                    </Row>

                    {statList ?
                        statList
                            .map((stat) =>
                            (
                                <Row key={stat.id} className={`m-1 p-1 rounded text-white align-items-center text-center 
                                    ${stat.background ?? "bg-secondary"}`}>
                                    <Col>{stat.time}</Col>
                                    <Col>{stat.dataKey}</Col>
                                    <Col>{stat.temp != 0 ? `${stat.temp} °C` : "---"}</Col>
                                    <Col>{stat.battery > 0 ? getBatteryString(stat.battery) : "---"}</Col>
                                </Row>
                            )
                        ) : 
                        <Row className="m-1 p-1 rounded bg-secondary text-white align-items-center text-center">
                            <Col>Ładowanie...</Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    );

}

export default Status;